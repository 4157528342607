import React from 'react';

function UnlockedSvg() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="60" cy="60" r="60" fill="url(#paint0_linear_4000_27808)" />
      <path d="M81.2307 100H37.2513C34.351 100 32 97.6704 32 94.7967V56.4228C32 53.549 34.351 51.2195 37.2513 51.2195H75.9794C78.8797 51.2195 81.2307 53.549 81.2307 56.4228V100Z" fill="#3D61B4" />
      <g opacity="0.2">
        <path opacity="0.4" d="M75.9791 51.2195H69.415C72.3153 51.2195 74.6663 53.549 74.6663 56.4228V64.0315C74.6663 65.5192 73.8135 66.8792 72.4648 67.5326C65.8064 70.7589 61.21 77.5404 61.21 85.3657C61.21 91.1537 63.725 96.3703 67.7286 100H81.2304V56.4228C81.2304 53.549 78.8794 51.2195 75.9791 51.2195Z" fill="#E8EDFB" />
      </g>
      <path d="M39.4111 37.0477V51.2195H46.3034V37.0477C46.3034 31.4042 50.9205 26.8294 56.616 26.8294C62.3115 26.8294 66.9286 31.4042 66.9286 37.0477V51.2195H73.8208V37.0477C73.821 27.6325 66.1182 20 56.6161 20C47.1141 20 39.4111 27.6325 39.4111 37.0477Z" fill="#E9E9E9" />
      <g opacity="0.3">
        <path d="M39.4111 46.0164H46.3034V51.2196H39.4111V46.0164Z" fill="#A5A5A5" />
      </g>
      <g opacity="0.3">
        <path d="M66.9277 46.0164H73.82V51.2196H66.9277V46.0164Z" fill="#A5A5A5" />
      </g>
      <path d="M81.2312 99.9999C89.3881 99.9999 96.0005 93.4479 96.0005 85.3657C96.0005 77.2834 89.3881 70.7314 81.2312 70.7314C73.0744 70.7314 66.4619 77.2834 66.4619 85.3657C66.4619 93.4479 73.0744 99.9999 81.2312 99.9999Z" fill="#4ADE80" />
      <path opacity="0.2" d="M88.9484 72.8867C90.3276 75.1122 91.1235 77.7306 91.1235 80.5336C91.1235 88.6158 84.5111 95.1678 76.3542 95.1678C73.5253 95.1678 70.8827 94.3792 68.6367 93.0127C71.2345 97.2041 75.903 99.9998 81.231 99.9998C89.3877 99.9998 96.0003 93.448 96.0003 85.3656C96.0003 80.0864 93.1785 75.4606 88.9484 72.8867Z" fill="#16A34A" />
      <path d="M75.7749 85.808C75.5309 85.5639 75.1351 85.5639 74.8911 85.808C74.647 86.0521 74.647 86.4478 74.8911 86.6918L78.6411 90.4418C78.8851 90.6859 79.2809 90.6859 79.5249 90.4418L88.6916 81.2752C88.9357 81.0311 88.9357 80.6354 88.6916 80.3913C88.4475 80.1472 88.0518 80.1472 87.8078 80.3913L79.083 89.116L75.7749 85.808Z" fill="white" stroke="white" strokeWidth="2" />
      <path d="M61.8669 70.0019C61.8669 67.1281 59.5158 64.7986 56.6156 64.7986C53.7153 64.7986 51.3643 67.1281 51.3643 70.0019C51.3643 71.9275 52.4208 73.6075 53.9898 74.5073V83.8194C53.9898 85.2562 55.1654 86.4209 56.6154 86.4209C58.0655 86.4209 59.241 85.2561 59.241 83.8194V74.5073C60.8103 73.6075 61.8669 71.9275 61.8669 70.0019Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_4000_27808" x1="60.5" y1="-223.749" x2="60.5" y2="112.251" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFD8EC" />
          <stop offset="1" stopColor="#CFD8EC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UnlockedSvg;
