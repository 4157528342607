export const SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  BASE: 'bs',
};

export const CONTROL_SIZES = {
  [SIZES.XS]: 7,
  [SIZES.SM]: 9,
  [SIZES.BASE]: 10,
  [SIZES.MD]: 11,
  [SIZES.LG]: 14,
};

export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  INLINE: 'inline',
};

export const DIRECTIONS = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
};

export const SELECTION_MODES = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
};

export const PICKER_VIEWS = {
  YEAR: 'year',
  MONTH: 'month',
  DATE: 'date',
};

export const STATUS = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const STEPS_STATUS = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
};

export const PLACEMENT = {
  TOP_START: 'top-start',
  TOP_CENTER: 'top-center',
  TOP_END: 'top-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_END: 'bottom-end',
  MIDDLE_START_TOP: 'middle-start-top',
  MIDDLE_START_BOTTOM: 'middle-start-bottom',
  MIDDLE_END_TOP: 'middle-end-top',
  MIDDLE_END_BOTTOM: 'middle-end-bottom',
};

export const DROPDOWN_ITEM_TYPE = {
  DEFAULT: 'default',
  HEADER: 'header',
  DIVIDER: 'divider',
  CUSTOM: 'custom',
};

export const DAY_DURATION = 86400000;

export const OPTION_PLAT_TYPE = [
  {
    value: 0,
    label: 'Hitam',
  },
  {
    value: 1,
    label: 'Kuning',
  },
  {
    value: 2,
    label: 'Putih',
  },
];

export const OPTION_CUSTOMER_TYPE = [
  {
    value: 0,
    label: 'Corporate',
  },
  {
    value: 1,
    label: 'Individual',
  },
];

export const OPTION_STATUS_MASTER_DATA = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
];

export const OPTION_GENDER = [
  {
    value: 0,
    label: 'Female',
  },
  {
    value: 1,
    label: 'Male',
  },
];

export const OPTION_CATEGORY_MASTER_DATA = [
  {
    value: 1,
    label: 'External',
  },
  {
    value: 0,
    label: 'Internal',
  },
];

export const OPTION_TIME_RANGE_MASTER_DATA = [
  {
    value: 1,
    label: 'Last 7 days ago',
  },
  {
    value: 2,
    label: 'Last 14 days ago',
  },
  {
    value: 3,
    label: 'Last 1 month ago',
  },
  {
    value: 4,
    label: 'Last 3 months ago',
  },
  {
    value: 5,
    label: 'Last 6 months ago',
  },
  {
    value: 6,
    label: 'Last year ago',
  },
];

export const OPTION_TIME_RANGE_DASHBOARD = [
  {
    value: 'last 7 days',
    label: 'last 7 days',
  },
  {
    value: 'last 14 days',
    label: 'last 14 days',
  },
  {
    value: 'last month ago',
    label: '1 month ago',
  },
  {
    value: 'last 3 months ago',
    label: '3 month ago',
  },
  {
    value: 'last 6 months ago',
    label: '6 month ago',
  },
  {
    value: 'last year ago',
    label: '1 year ago',
  },
];
