import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/auth/sign-in',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiUserMe () {
    return ApiService.fetchData({
        url: '/auth/me',
        method: 'get',
        
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/auth/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/passwords/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data, resetToken) {
    return ApiService.fetchData({
        url: `/passwords/reset-password/${resetToken}`,
        method: 'post',
        data
    })
}
