import React from 'react';

function MailSvg() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="60" cy="60" r="60" fill="url(#paint0_linear_4000_28413)" />
      <path d="M92.3125 48.3375L66.4625 31.425C64.5607 30.1184 62.3075 29.4189 60 29.4189C57.6926 29.4189 55.4394 30.1184 53.5375 31.425L27.6875 48.3375C26.4604 49.2729 25.4664 50.4796 24.7834 51.8632C24.1003 53.2467 23.7466 54.7695 23.75 56.3125V86.25C23.75 87.5685 24.0107 88.874 24.5172 90.0913C25.0236 91.3087 25.7658 92.4138 26.701 93.3433C27.6362 94.2727 28.746 95.008 29.9665 95.5068C31.187 96.0057 32.4941 96.2582 33.8125 96.25H86.25C88.9022 96.25 91.4457 95.1964 93.3211 93.3211C95.1965 91.4457 96.25 88.9022 96.25 86.25V56.3125C96.2535 54.7695 95.8998 53.2467 95.2167 51.8632C94.5336 50.4796 93.5396 49.2729 92.3125 48.3375Z" fill="#F97316" />
      <path d="M85 96.25V26.25C85 25.587 84.7366 24.9511 84.2678 24.4822C83.7989 24.0134 83.163 23.75 82.5 23.75H37.5C36.837 23.75 36.2011 24.0134 35.7322 24.4822C35.2634 24.9511 35 25.587 35 26.25V96.25H85Z" fill="#E9E9E9" />
      <path d="M96.2501 56.3125V86.25C96.2501 88.9022 95.1965 91.4457 93.3211 93.3211C91.4458 95.1965 88.9022 96.25 86.2501 96.25H33.8126C31.1604 96.25 28.6168 95.1965 26.7415 93.3211C24.8661 91.4457 23.8126 88.9022 23.8126 86.25V56.3125C23.8074 54.7349 24.1803 53.1789 24.9001 51.775L53.6501 77.325C55.4561 78.8544 57.746 79.6938 60.1126 79.6938C62.4791 79.6938 64.769 78.8544 66.5751 77.325L95.3251 51.775C95.9887 53.1928 96.3057 54.7481 96.2501 56.3125Z" fill="#FDBA74" />
      <path d="M93.75 92.85C92.8129 93.9167 91.6594 94.7716 90.3662 95.3578C89.073 95.944 87.6699 96.2482 86.25 96.25H33.8125C32.487 96.2511 31.1745 95.9886 29.9513 95.4779C28.7281 94.9671 27.6187 94.2183 26.6875 93.275L53.6 69.525C55.3675 67.966 57.6432 67.1057 60 67.1057C62.3568 67.1057 64.6325 67.966 66.4 69.525L93.75 92.85Z" fill="#FB923C" />
      <path d="M50.8075 43.1388C50.394 42.7405 49.7236 42.7405 49.3101 43.1388C48.8966 43.5372 48.8966 44.1829 49.3101 44.5812L55.6631 50.7012C56.0766 51.0996 56.747 51.0996 57.1605 50.7012L72.6899 35.7412C73.1034 35.3429 73.1034 34.6971 72.6899 34.2987C72.2764 33.9004 71.6061 33.9004 71.1925 34.2987L56.4118 48.5375L50.8075 43.1388Z" fill="#22C55E" stroke="#22C55E" strokeWidth="2" />
      <defs>
        <linearGradient id="paint0_linear_4000_28413" x1="60.5" y1="-223.749" x2="60.5" y2="112.251" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFD8EC" />
          <stop offset="1" stopColor="#CFD8EC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MailSvg;
